
.parts-intro-button button {
    border: none;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.33px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #051B23;
    background-color: #FFC629;
    height: 56px;
    width: 350px;
    text-transform: uppercase;
    border-radius:  4px;
}
.parts-intro-button2 button{
    border: none;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.33px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: #051B23;
    color: #FFC629;
    height: 56px;
    width: 280px;
    text-transform: uppercase;
    border-radius:  4px;
}
.parts-content2-right-container{
    width: 50%;
    display: flex;
    flex-direction: column;
   
    align-items: flex-start; 
   margin-top:40px;
}
@media(max-width:1600px) {
.parts-intro-button button {
    font-size: 15px;
    line-height: 18px;
    width: 300px;
    height:46px;
}
.parts-intro-button2 button{
    font-size: 15px;
    line-height: 18px;
    width: 220px;
    height:46px;
}
}
@media(max-width:1025px){
.buy-sell-intro-button button {
    font-size: 14px;
     line-height: 16px;     
}
}
@media (max-width:767px){
    .parts-content2-right-container{
        width:100%;
    }
}