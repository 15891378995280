* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  font-family: 'Rubik', sans-serif; /* Set global font to Rubik */
}

html,body {
  font-family: 'Rubik', sans-serif; /* Apply Rubik to the body */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content-width {
  max-width: 1400px;
  margin: 0px auto;
}

@media (max-width: 1600px) {
  .content-width {
    max-width: 1100px;
  }
}

@media (max-width: 1200px) {
  .content-width {
    margin: 0px 30px;
  }
}

@media (max-width: 1130px) and (min-width: 768px) {
  .content-width {
    max-width: 100%;
    margin: 0px 20px;
  }
}

@media (max-width: 767px) {
  .content-width {
    margin: 0px 20px;
  }
}

@media only screen and (max-width: 768px) {
  html, body {
    width: 100%;
    overflow-x: hidden;
  }
}
.button-global1 {
  /* initial styles */
  color: #FFC629 ;
  background-color:#051B23 ;
 border:transparent;
 padding:10px 20px;
 margin-top:20px;
  border-radius:4px;
  font-size:18px;
  line-height:32px;
}

.button-global1:hover {
  color: #051B23;
  background-color: #FFC629;
  transform: translate3d(2px, -2px, 2px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}
