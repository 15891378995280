.uae-banner-background {
    width: 100%;
    height: 550px;
    align-content: center;
}

.uae-banner-background-text {
    font-family: Rubik;
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
}

.uae-banner-background-text span {
    position: relative;
}

.uae-banner-background-text span img {
    position: relative;
    right: 70px;
    top: 40px;
    width: 100px;
    height: 20px;
}

@media(max-width:1600px) {
    .uae-banner-background-text span img {
        top: 30px;
        width: 80px;
        height: 15px;
    }
}

@media(max-width:1200px) {
    .uae-banner-background-text {
        font-family: Rubik;
        font-size: 45px;
        font-weight: 700;
        line-height: 52px;
        text-align: left;
        margin-left: 15px;
    }
}

@media(max-width:450px) {
    .uae-banner-background-text {
        font-family: Rubik;
        font-size: 36px;
        line-height: 36px;
    }

    .uae-banner-background {
        width: 100%;
        height: 600px;
        align-content: center;
    }

    .uae-banner-background-text span img {
        position: absolute;
        right: 0px;
        top: 40px;
        width: 50px;
        height: 10px;
    }
}

.prouct-listing-container {
    margin-top: 60px;
}

.used-equipment-heading {
    margin-top: 60px;
}

.product-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 40px;
}

.shop-card-container {
    /* width: 280px; */
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #F4F7F8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 0.5s ease-in;
}

.shop-card-container:hover {
    transform: scale(1.03);
}

.shop-card-image {
    width: 100%;
    /* Full width of container */
    height: 280px;
    /* Fixed height, adjust to your desired size */
    object-fit: cover;
    /* Maintains the aspect ratio, but may crop the image */
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
}

.shop-card-container p {
    margin-left: 20px;

}

.product-title {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    margin-bottom: 10px;
    color: #014A60;


}

.product-details {
    font-family: Rubik;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.58px;
    text-align: left;
    margin-bottom: 5px;
    color: #014A60;

}

.product-view-details {
    width: 100%;
    border: none;
    background-color: #C0CDD1;
    padding: 10px 0;
    margin-top: 5px;
    border-radius: 0 0 10px 10px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.5px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #014A60;
    cursor: pointer;

}

@media(max-width:1600px) {
    .product-list-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20x;
        margin-top: 40px;
    }

    .product-view-details {
        margin-top: 0px;
    }
}

@media(max-width:1200px) {
    .product-list-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25x;
        margin-top: 40px;
    }

    .product-view-details {
        margin-top: 5px;
    }
}

@media(max-width:769px) {
    .product-list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 35px;
        margin-top: 40px;
    }

    .product-view-details {
        margin-top: 5px;
    }
}

@media(max-width:600px) {
    .product-list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 40px;
    }

}

@media(max-width:500px) {

    .shop-card-container {
        max-width: 450px;
    }

    .product-list-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        margin-top: 40px;
        justify-content: center;

    }

    .product-view-details {
        margin-top: 5px;
    }
}

.product-view-details a {
    text-decoration: none;
    color: #014A60;
}

.product-view-details:hover {
    background-color: #FCC22C;
}

.product-view-details a:hover {
    color: #014A60;
}

/* styles  for filters */
/* Styles for filters */
.used-equipment-filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    /* Add spacing between elements */
    width: 100%;
    /* Ensure it spans the full width */
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.filter-mobile-search {
    display: none;
}

.filter-heading-mobile {
    display: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    color: #014A60;
}

.filter-heading-mobile:hover {
    color: #FFC629;
}

.used-equipment-filters {
    flex: 1;
    /* Allow filters to take up remaining space */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
    transition: max-height 0.3s ease-in-out;
}

.used-equipment-filters1 {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 15px;
}

.used-equipment-page-filters-container hr {
    border: 1px solid #E7E7E7;
}

.filter-searchbar-mobile {
    display: none;
}

@media (max-width: 900px) {
    .MuiAutocomplete-root {
        width: 100% !important;
        /* Force full width for all Autocomplete components */
    }

    .used-equipment-filters-container {
        margin-top: 0px;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
    }

    .used-equipment-filters {
        flex-direction: column;
        align-items: flex-start;
        max-height: 0;
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: -10px;
    }

    .filter-heading-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       align-items: center;
       width:100%;
       margin-top:20px;
    }

    .filters-open {
        max-height: 500px;
        /* Adjust based on content size */
    }

    .used-equipment-filters1 {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
    }

    .filters-desktop-search,
    .filters-heading-desktop {
        display: none;
    }

    .filter-mobile-search {
        width: 50%;
        border-left:1px solid #FAFAFA ;
        display: block;
        text-align: right;
    }

    .filter-heading-mobile {
        display: block;
        text-align: left;
      
    }
}