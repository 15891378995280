/* Spinner overlay to cover the entire screen */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure the spinner is on top of other content */
  }
  
  /* SVG spinner styling */
  .spinner-overlay svg {
    width: 150px; /* Adjust size of the spinner */
    height: 150px; /* Adjust height of the spinner */
    transform: rotate(0deg);
  }
  
  /* Adjust the individual circles inside the spinner */
  .spinner-overlay circle {
    transform-origin: 50% 50%;
  }
  