.outer-line {
    height: 780px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-top: -130px;
    z-index: 0;
}

.animated-image {
    display: block;
    width: 85px;
    height:15px;
    margin: auto;
    transform: translateX(40px) translateY(-12px);
}

.text-stable {
    font-family: rubik;
    font-size: 72px;
    font-weight: 600;
    line-height: 76px;
    text-align: center;
    padding: 250px 0px 5px 0px;
    color: #ffffff;
    width: 900px;
}

.inner-text {
    height: 280px;
    font-size: 85px;
    font-weight: 600;
    line-height: 96px;
    text-align: center;
    overflow: hidden;
}

.inner-text .text-rotate {
    font-family: rubik;
    color: #fcc22c;
    position: relative;
    animation: rotateTextanimate 18s linear infinite; /* Match with JS interval */
}

@keyframes rotateTextanimate {
    0%, 15% { top: 0; }
    20%, 30% { top: -90px; }
    35%, 45% { top: -190px; }
    50%, 60% { top: -290px; }
    65%, 75% { top: -390px; }
    80%, 100% { top: -490px; }
}

.svg-container {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    left: 0;
    z-index: 0;
}

.svg-container.active {
    opacity: 1;
    z-index: 1;
}

.svg-wrapper {
    width: 100%;
    text-align: center;
    margin-top: auto;
    position: relative;
    height: 140px;
}

.image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    bottom: 0;
    display: flex;
    height: 100%;
}

/* Responsive styles */
@media (min-width: 800px) and (max-width: 1320px) {
    .svg-container { top: 20%; }
}

@media (min-width: 540px) and (max-width: 799px) {
    .svg-container { top: 30%; }
}

@media (min-width: 300px) and (max-width: 539px) {
    .svg-container { top: 37%; }
}
@media (max-width:1600px){
    .outer-line { height: 700px; }
    .text-stable { font-size: 64px; line-height: 64px; width: 100%; }
    .inner-text { height: 280px; font-size: 64px; line-height: 76px; }

    @keyframes rotateTextanimate {
        0%, 15% { top: 0; }
        20%, 30% { top: -75px; }
        35%, 45% { top: -150px; }
        50%, 60% { top: -225px; }
        65%, 75% { top: -300px; }
        80%, 100% { top: -375px; }
    }

}
@media (max-width: 1050px) {
    .outer-line { height: 680px; }
    .text-stable { font-size: 50px; line-height: 56px; width: 100%; }
    .inner-text { height: 200px; font-size: 50px; line-height: 70px; }

    @keyframes rotateTextanimate {
        0%, 15% { top: 0; }
        20%, 30% { top: -75px; }
        35%, 45% { top: -145px; }
        50%, 60% { top: -215px; }
        65%, 75% { top: -285px; }
        80%, 100% { top: -355px; }
    }
}
@media (max-width:768px){
    .outer-line { height: 680px; }
    .text-stable { font-size: 42px; line-height: 48px; width: 100%; }
    .inner-text { height: 200px; font-size: 48px; line-height: 70px; }
    .animated-image { width: 48px; height: 9px; transform: translateX(30px) translateY(-10px); }
}


@media (min-width: 360px) and (max-width: 500px) {
    .outer-line { height: 700px; }
    .text-stable { font-size: 34px; line-height: 34px; padding: 250px 25px 0px 25px; width: 100%; }
    .inner-text { height: 110px; font-size: 34px; line-height: 50px; }
    .animated-image { width: 50px; height: 7px; transform: translateX(30px) translateY(-10px); }

    @keyframes rotateTextanimate {
        0%, 15% { top: 0; }
        20%, 30% { top: -50px }
        35%, 45% { top: -100px; }
        50%, 60% { top: -150px; }
        65%, 75% { top: -200px; }
        80%, 100% { top: -250px; }
    }
}

@media (max-width: 359px) {
    .outer-line { height: 700px; }
    .text-stable { font-size: 30px; line-height: 34px; padding: 300px 20px 0px 20px; width: 100%; }
    .inner-text { height: 125px; font-size: 30px; line-height: 50px; }
    .animated-image { width: 50px; height: 7px; transform: translateX(30px) translateY(-10px); }
}
