.single-job-post-container {
    margin-top: 80px;
}

.single-job-post-detials {
    margin-top: 20px;
    margin-bottom: 80px;
}

.single-job-post-title {
    font-family: Rubik;
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    text-align: left;
    color: #387991;
}

.single-job-post-details-heading {
    font-family: Rubik;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #051B23;
    margin-top: 15px;
}

.single-job-post-details-content {
    font-family: Rubik;
    font-size: 22px;
    font-weight: 300;
    line-height: 36px;
    text-align: left;
    color: #051B23;
}

.single-job-post-additional-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.single-job-post-additional-details .single-job-post-details-content {
    margin-top: 17px;
    margin-left: 5px;
}

@media (max-width: 1600px) {
    .single-job-post-title {
        font-size: 36px;
        line-height: 36px;
    }

    .single-job-post-details-heading,
    .single-job-post-details-content {
        font-size: 18px;
        line-height: 22px;
    }

    .single-job-post-details-heading {
        margin-top: 20px;
    }

    .single-job-post-details-content {
        margin-top: 5px;
    }

    .single-job-post-additional-details .single-job-post-details-content {
        margin-top: 22px;
        margin-left: 5px;
    }
}

/* Application styles */
.single-job-post-application-full-container {
    background-color: #F5F9FA;
}

.single-job-post-application-container {
    display: flex;
    /* justify-content: space-between; */
    padding: 80px 0px;
    width: 100%;
    box-sizing: border-box;
}



.job-application-left-container {
    padding-right: 100px;
    width: 50%;
    box-sizing: border-box;
}
/* Base style for .responsive-container */
.responsive-container {
    width: 50%;
}


.file-upload-container {
    margin-top: 20px;
}

.job-application-left-heading {
    font-family: Rubik;
    font-size: 44px;
    font-weight: 300;
    line-height: 44px;
    text-align: left;
    color: #051B23;
    margin-bottom: 50px;
}

.job-application-address h5 {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.59px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #051B23;
    margin-bottom: 8px;
}

.job-application-address p {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #051B23;
    margin-bottom: 40px;
}

.file-upload-container {
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
}

.job-application-apply-button {
    font-family: Rubik;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.33px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #FFC629;
    background-color: #051B23;
    padding: 15px 30px;
    margin-top: 20px;
    border-radius: 4px;
}

@media (max-width: 1600px) {
    .job-application-apply-button {
        font-size: 14px;
        line-height: 16px;
        padding: 10px 25px;
    }
    .job-application-left-container {
        padding-right: 100px;
        width: 45%;
        box-sizing: border-box;
    }
    /* Base style for .responsive-container */
    .responsive-container {
        width: 55%;
    }
}

@media (max-width: 1200px) {
    .job-application-right-container {
        margin-right: 30px;
    }
    .job-application-left-container {
        padding-right: 100px;
        width: 40%;
        box-sizing: border-box;
    }
    /* Base style for .responsive-container */
    .responsive-container {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .single-job-post-application-container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding-right:20px;
        padding:80px 0 50px 0;
    }
    
    .job-application-left-container
    {
        width: 100%; 
        padding-right: 0;
    }
    .job-application-left-heading{
        font-family: Rubik;
        font-size: 24px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .responsive-container {
        width: 95% !important; /* Use !important to override other styles */
        margin-bottom:50px;
    }

    .job-application-right-container {
        width: 100%;
    }
    .job-application-apply-button {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.59px;
        letter-spacing: 0.05em;
        text-align: center;

    }
}

