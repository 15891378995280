/* Centering the login form vertically and horizontally */
.login-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
   margin:20px 0px;
}

.login-page-outer-container {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}
.login-form-container{
    padding:50px;
    border-radius:8px;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.5);
}
.login-forget-password-button{
    text-align: center;
    color: #666666;
    text-decoration: none;
}
.login-forget-password-button:hover{
      text-decoration: underline;
}
@media (max-width:500px){
    .login-form-container{
        padding:35px;
    }
    .login-page-outer-container img{
        width:75%;
    }
}