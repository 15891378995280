


.enquire-form-page-outer-container {
    width: 100vw;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the enquire-form-container */
}

.enquire-form-container {   
    width: 100%;
    display: flex; 
    justify-content: space-between; 
    align-items: center;  
    height:550px;
   }


.enquire-form-left-container{
    width: 55%;
    height: 100%;
    margin-left:300px;
    margin-right:280px;
    margin-top:100px;
    /* align-content: center; */
    
}
.enquire-form-right-container{
    width: 45%;
    height: 100%;
    margin-left:500px;
}
.enquire-form-each-slide {
    color: #FFFFFF;
    transition: fade-in 0.5s ease-in-out;
    opacity: 1;
    line-height: 1.25em;
    font-size: 70px;
    font-weight:700;
    font-family: rubik;
      
}


.enquire-form-background-container{
    background-color: #FFFFFF;
    padding:50px;
}

@media (max-width:2000px){
    .enquire-form-left-container{
        width: 60%;
        margin-left:200px;
        margin-right:100px;
    }
    .enquire-form-right-container{
        width: 40%;
        margin-left:200px;
    }
}

@media (max-width:1600px){
    .enquire-form-left-container{
        width: 60%;
        margin-left:100px;
        margin-right:100px;
    }
    .enquire-form-right-container{
        width: 40%;
        margin-left:100px;
    }
}
@media (max-width:1200px){
    .enquire-form-each-slide {
        font-size: 58px;
        line-height: 1.1em;
    }
    .enquire-form-left-container{
        width: 52%;
        margin-left:100px;
        
    }
    .enquire-form-right-container{
        width: 48%;
        margin-left:100px;
    }
    .enquire-form-background-container{
        background-color: #FFFFFF;
        padding:20px;
    }
}
@media (max-width:1023px){
    .enquire-form-each-slide {
        font-size: 45px;
        line-height: 1.2em;
    }
    .enquire-form-left-container{
        width: 52%;
        margin-left:100px;
        margin-right:10px;
    }
    .enquire-form-right-container{
        width: 48%;
        margin-left:100px;
    }
    
}
@media (max-width:767px){
    .enquire-form-container {   
        width: 100%;
        display: flex; 
        flex-direction: column;
        align-items: center;  
        margin:0px 50px;
    }
    .enquire-form-left-container{
        display: none;
    }
    .enquire-form-right-container{
        width: 100%;
        height: 100%;
        margin-left:0px;
    }
}
@media (max-width:500px){
    .enquire-form-container {   
          margin:0px 20px;
    }
}