.full-width-caurosel-outer-container {
    position: relative;
    margin-top:50px;
}
    

/* Carousel container */
.fullwidth-carousel-container {
   max-width:1400px;
    /* height: 500px; */
    margin: 0 auto; 
   
   
}

/* Background vector positioning */
.background-vector1 {
    position: absolute;
    top: -85px;
}

/* Adjustments for larger screens */
@media (max-width: 1600px) {
    .fullwidth-carousel-container {
        max-width: 1100px;
    }
    .background-vector1 {
        position: absolute;
        top: -55px;
        height: 600px;
    }
}

/* For screens 1350px and smaller - full width */
@media (max-width: 1350px) {
    .fullwidth-carousel-container {
        width: 100vw;  /* Full width of the viewport */
        max-width: 100vw;  /* No max-width limit */
        margin: 0;
      padding-top:10px;
        overflow: hidden;  /* Prevent overflow */
    }
    .background-vector1 {
        position: absolute;
        top: -43px;
        height: 600px;
    }
}
@media(max-width:769px){
    .background-vector1{
        height: 400px;
        top:-10px;
}
}
