.festivals-gallery-container-popup-overlay {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.7);
    display:flex;
    align-items: center;
    justify-content:center;
    z-index:1000;
    width:100%;
    overflow-x:hidden;
    box-sizing: border-box;
}
.festivals-gallery-container-popup-content{
    position:relative;
    width: 100%;
}

.gallery-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none; /* Use 'background' instead of 'background-color' */
    border: none; /* Remove the border */
    font-size: 20px; /* Adjust the size of the close button */
    cursor: pointer;
   color:#ffffff;
}
.festivals-gallery-content{
    width:90%;
    height:70vh;
    display:flex;
    align-items: center;
    justify-content:center;
} 
.festivals-gallery-content img{
    width:100%;
    height:100%;
   object-fit: contain;
}